import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Spinner from "../app/shared/Spinner";
import PrivateRoute from "./app-config/PrivateRoute";
import Payment from "./Payment";

const Dashboard = lazy(() => import("./TopAdminSection/dashboard"));
const StudentDashboard = lazy(() => import("./StudentSection/StudentDashboard"));
const AllUser = lazy(()=> import("./TopAdminSection/user"))
const Buttons = lazy(() => import("./basic-ui/Buttons"));
const Dropdowns = lazy(() => import("./basic-ui/Dropdowns"));
const Typography = lazy(() => import("./basic-ui/Typography"));
const BasicElements = lazy(() => import("./form-elements/BasicElements"));
const BasicTable = lazy(() => import("./tables/BasicTable"));
const Mdi = lazy(() => import("./icons/Mdi"));
const ChartJs = lazy(() => import("./charts/ChartJs"));
const Error404 = lazy(() => import("./error-pages/Error404"));
const Error500 = lazy(() => import("./error-pages/Error500"));
const Login = lazy(() => import("./user-pages/Login"));
//const Login = lazy(() => import("./user-pages/loginNew"));
const Register1 = lazy(() => import("./user-pages/Register"));
const Lockscreen = lazy(() => import("./user-pages/Lockscreen"));
const BlankPage = lazy(() => import("./general-pages/BlankPage"));
const Role = lazy(()=> import("./TopAdminSection/role"))
const Profile = lazy (()=>import("./profile-page"))
const StudentFee = lazy(()=> import("./StudentSection/StudentFee"))
const AdminDashboard = lazy(()=> import("./AdminSection/AdminDashboard"))
const AllStudent = lazy(()=> import("./AdminSection/AllStudent"))
const Admission = lazy(()=> import("./AdminSection/Admission") )
const TeacherDashboard = lazy(()=> import("./TeacherSection/TeacherDashboard"))
const ResultEntry= lazy(()=> import("./ResultEntry"))
const ExamResult= lazy(()=> import("./ExamResult"))
const RecoverUser = lazy(()=>import("./TopAdminSection/RecoveryUser"))
const ExamPermission = lazy(()=>import("./ExamPermission"))
const Report = lazy(()=>import("./Report"))
const AdmitCard = lazy(()=>import("./AdmitCard"))
const TeacherStaff = lazy(()=>import("./TeacherStaff"))
const AllList = lazy(()=>import("./AllList"))
//const Payment = lazy(()=>import("./Payment"))
const Transaction = lazy(()=> import("./Transaction"))
const MessageList = lazy(()=> import('./MessageReport'))
const Notes = lazy(()=> import('./NotesReport'))
const RollNumberEntry= lazy(()=> import("./RollNumberEntry"))

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}> 
        <Switch>
          <Route exact path='/' component={BlankPage}/>
          <Route exact path='/user-pages/login-1' component={Login}/>
          <Route exact path='/user-pages/register-1' component={Register1} />
          {/* <Route exact path='/emailVerification/:email/:token' component={CreatePassword}/> */}
          <PrivateRoute path="/dashboard" component={Dashboard} exact/>
          <PrivateRoute path="/st_dashboard" component={StudentDashboard} exact/>
          <PrivateRoute path="/basic-ui/buttons" component={Buttons} exact/>
          <PrivateRoute path="/basic-ui/dropdowns" component={Dropdowns} exact/>
          <PrivateRoute path="/basic-ui/typography" component={Typography} exact />
          <PrivateRoute path="/form-Elements/basic-elements" component={BasicElements} exact/>
          <PrivateRoute path="/tables/basic-table" component={BasicTable} exact/>
          <PrivateRoute path="/icons/mdi" component={Mdi} exact/>
          <PrivateRoute path="/charts/chart-js" component={ChartJs} exact/>
          <PrivateRoute path="/role" component={Role} exact/>
          <PrivateRoute path="/profile" component={Profile} exact/>
          <PrivateRoute path="/allUser" component={AllUser} exact/>
          <PrivateRoute path="/st_fees" component={StudentFee} exact/>
          <PrivateRoute path="/admin_dashboard" component={AdminDashboard} exact/>
          <PrivateRoute path="/allstudent" component={AllStudent} exact/>
          <PrivateRoute path="/admission" component={Admission} exact/>
          <PrivateRoute path="/teacher_dashboard" component={TeacherDashboard} exact/>
          <PrivateRoute path="/resultEntry" component={ResultEntry} exact/>
          <PrivateRoute path="/examResult" component={ExamResult} exact/>
          <PrivateRoute path='/recoverUser' component={RecoverUser} exact/>
          <PrivateRoute path='/examPermission' component={ExamPermission} exact/>
          <PrivateRoute path='/report' component={Report} exact/>
          <PrivateRoute path='/admitCard' component={AdmitCard} exact/>
          <PrivateRoute path='/allTeacherAndStaff' component={TeacherStaff} exact/>
          <PrivateRoute path='/allList' component={AllList} exact/>
          <PrivateRoute path='/payment' component={Payment} exact/>
          <PrivateRoute path='/transaction' component={Transaction} exact/>
          <PrivateRoute path='/messageList' component={MessageList} exact/>
          <PrivateRoute path='/notes' component={Notes} exact/>
          <PrivateRoute path='/rollNumberEntry' component ={RollNumberEntry} exact/>
          {/* <PrivateRoute path="/user-pages/lockscreen" component={Lockscreen} exact/> */}
          <PrivateRoute path="/error-pages/error-404" component={Error404} exact/>
          <PrivateRoute path="/error-pages/error-500" component={Error500} exact/>
          {/* <PrivateRoute path="/general-pages/blank-page" component={BlankPage} exact/> */}
            
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
