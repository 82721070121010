import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import SettingsPanel from './shared/SettingsPanel';
import Footer from './shared/Footer';
import OneSignal from 'react-onesignal';
import { AuthProvider } from 'react-auth-kit'
import { GoogleOAuthProvider } from '@react-oauth/google';
import {decryptAESObj} from './util/helper';

let USER = localStorage.getItem("userInformation") && JSON.parse(localStorage.getItem("userInformation"));
USER = decryptAESObj(USER)
class App extends Component {
  state = {}
  componentDidMount() {
    this.onRouteChanged();
    OneSignal.init({ appId: '1ad13ded-ebe0-4bdc-b8c3-23a02796e880' });
  }
  render () {
    let navbarComponent = USER ? <Navbar/> : '';
    let sidebarComponent = USER ? <Sidebar/> : '';
    let SettingsPanelComponent = USER ? <SettingsPanel/> : '';
    let footerComponent = USER ? <Footer/> : '';
    return (
      <div className="container-scroller">
        <GoogleOAuthProvider clientId="7867430185-dtli1ehll6u6v9npk4p38i2takktduh4.apps.googleusercontent.com">
        { navbarComponent }
        <div className="container-fluid page-body-wrapper">
          { sidebarComponent }
          <div className="main-panel">
            <div className="content-wrapper">
              <AppRoutes/>
              { SettingsPanelComponent }
            </div>
            { footerComponent }
          </div>
        </div>
        </GoogleOAuthProvider>
      </div>
    );
  }

  onRouteChanged() {
      if (!USER) {
        this.setState({
          isFullPageLayout: true
        })
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
      } else {
        this.setState({
          isFullPageLayout: false
        })
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
  }
}

export default  (withRouter(App));