import React, { Component } from "react";
import Axios from "axios";
import {SETTING} from "../app-config/cofiguration";
import {decryptAESObj, generateSixDigitId, logoutFunc} from "../util/helper";
let USER = localStorage.getItem("userInformation") && JSON.parse(localStorage.getItem("userInformation"));
USER = decryptAESObj(USER)
export class AddNote extends Component{
    constructor(props){
        super(props)
        this.state={
            notes:this.props.selectedUser.userInfo.notes ? this.props.selectedUser.userInfo.notes: [],
            inputValue:'',
            selectedUser: this.props.selectedUser
        } 
    }
   
    updateUser=async()=> {
        const userId = this.state.selectedUser._id
        let dataToSend = {
            notes: this.state.notes,
            addNote: true
        };
    
        let options = SETTING.HEADER_PARAMETERS;
        options['Authorization'] = localStorage.getItem("token")
        await Axios.post(SETTING.APP_CONSTANT.API_URL+`admin/updateUser/`+userId, dataToSend,{headers: options})
        .then((res) => {
          if (res && res.data.success) {
            this.props.setDataFromNote({success: true, message:res.data.message})
            // toast["success"](res.data.message)
          } else {
            this.props.setDataFromNote({success: false, message:res.data.message})
            // toast["error"](res.data.message);
          }
        })
        .catch((err) =>{
          this.setState({
            loading2:false
          })
          if(err && err.response && err.response.status===401){
            // toast.error('Token expired.');
            this.props.setDataFromNote({success: false, message:'Token expired.'})
            logoutFunc()
          }else{
            const errorMessage= err && err.response && err.response.data && err.response.data.message?err.response.data.message :`Error while update note.`
            this.props.setDataFromNote({success: false, message:errorMessage})
            // toast["error"](errorMessage);
          }
        });
      }

    inputChangeHandler=(event) =>{
        this.setState({
          inputValue: event.target.value,
        });
      }

    addNote=(event)=> {
        event.preventDefault();
        const notes = [...this.state.notes];
        notes.unshift({
            id: generateSixDigitId(),
            task: this.state.inputValue,
            isCompleted: false,
            created: new Date(),
            delete:false,
            insertedBy: USER.userInfo.userId,
            insertedName: USER.userInfo.fullName
        });

        this.setState({
        notes: notes,
        inputValue: "",
        },()=> this.updateUser());
    }
    statusChangedHandler=(event, id)=> {
        let note ={...this.state.notes[id]};
        note.isCompleted = event.target.checked;
        note['updatedDate'] = new Date();
        const notes = [...this.state.notes];
        notes[id] = note;
        this.setState({
          notes: notes,
        },()=> this.updateUser());
      }
      removeNote=(id)=> {
        const notes = [...this.state.notes];
        let note ={...this.state.notes[id]};
        note['delete']= true
        note['updatedDate'] = new Date();
        note['deletedBy']= USER.userInfo.userId
        note['deletedName'] =USER.userInfo.fullName
        notes[id] = note
        this.setState({
          notes: notes,
        },()=> this.updateUser());
      }


render(){
    return(
        <>
            <div className="card">
              <div className="card-body">
                <h4 className="card-title text-white">Note</h4>
                <form className="add-items d-flex" onSubmit={this.addNote}>
                  <input
                    type="text"
                    className="form-control h-auto"
                    placeholder="Add note here..."
                    value={this.state.inputValue}
                    onChange={this.inputChangeHandler}
                    required
                  />
                  <button  type="submit" className="btn btn-gradient-primary font-weight-bold px-lg-4 px-3" >
                    Add
                  </button>
                </form>
                <div className="list-wrapper">
                  <ul className="d-flex flex-column todo-list">
                    {this.state.notes.map((note, index) => {
                        if(note.delete===false){
                            return (
                                <li className={note.isCompleted ? "completed" : null} key={note.id}>
                                <div className="form-check">
                                  <label htmlFor="" className="form-check-label">
                                    <input
                                      className="checkbox"
                                      type="checkbox"
                                      checked={note.isCompleted}
                                      onChange={(event) =>
                                             this.statusChangedHandler(event, index)}
                                    />
                                   
                                    <i className="input-helper"></i>
                                  </label>
                                </div>
                                <div className="form-check">
                                    ({new Date(note.created).toLocaleDateString("en-GB")})
                                    <span className="task-text" >{note.task} </span>
                                    <i className="input-helper"></i>
                                </div>
                                <i
                                  className="remove mdi mdi-close-circle-outline"
                                  onClick={() => this.removeNote(index)}
                                ></i>
                              </li>
                              );
                        }
                    
                    })}
                  </ul>
                </div>
              </div>
            </div>
         
      </>
    )
} 
};

  
  
  
  